/* 
** The Header Media Queries **
** Tweak as per your needs **
*/
.brand {
  font-weight: bold;
  font-size: 20px;
  display: flex;
  align-items: center;
}
.brand .t1 {
  padding-left: 5px;
  color: #fff;
}
.site-header {
  position: relative;
  background-color: #1ca7e0;
}

.site-teacher-header {
  background-color: #1ca7e0;
  width: 100%;
}
.site-header__wrapper {
  display: flex;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.site-header__end {
  margin-left: auto;
}
.header-profile-user {
  display: flex;
  align-items: center;
  position: relative;
}
.user-available-dot {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  background: #6dd400;
  bottom: 0;
  left: 0;
  border: 1px solid #fff;
}
.text-start {
  color: #fff;
  padding-left: 8px;
  text-transform: capitalize;
}
.help-icon a {
  display: flex !important;
}
.notification-icon a {
  display: flex !important;
  position: relative;
}
.notification-count {
  position: absolute;
  padding: 0.05em 0.35em;
  border-radius: 50px;
  background: #ff7f41;
  top: 8px;
  right: 5px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-items: center;
  line-height: 1.1;
  font-size: 12px;
  z-index: 45;
}
.exam-icon a{
  display: flex !important;
}
.round-user-image {
  height: 34px;
  width: 34px;
  border-radius: 50% !important;
  border: 2px solid #fff;
}
.nav__wrapper {
  display: flex;
  align-items: center;
  margin: 7px 0;
}
.bdr-left{
  position: relative;
  margin-left: 30px;
}
.bdr-left::before{
  position: absolute;
  content: '';
  height: 36px;
  width: 1px;
  background: #46c9ff;
  top:7px;
  left: -20px;

}
.language-select span{
  display: flex !important;
  align-items: center;
}
.language-select span img{
  width: 18px;
  height: 18px;
  border-radius: 50% !important;
  border: 1px solid #fff;
}
.language-select a .t1{
  color: #fff;
  padding-left: 5px;
  padding-top: 1px;
}
.d-none{
  display: none;
}
@media (min-width: 660px) {
  .site-header__wrapper {
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media (max-width: 659px) {
  .site-header__end {
    padding-right: 4rem;
    margin-left: auto;
  }
}

@media (min-width: 660px) {
  .nav__wrapper {
    display: flex;
  }
}

@media (max-width: 659px) {
  .nav__wrapper {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: -1;
    background-color: #d9f0f7;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-100%);
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  }
  .nav__wrapper.active {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
}

.nav__item a {
  display: block;
  padding: 0.2rem 1rem;
}
.auth__item a {
  display: block;
  margin: 0 5px 0 5px;
  padding: 0.5rem 0.75rem;
}

.nav__toggle {
  display: none;
}
@media (max-width: 659px) {
  .nav__toggle {
    display: block;
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
}
