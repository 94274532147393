@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
.wrapper {
  max-width: 1280px;
  padding-left: 24px;
  padding-right: 24px;
  margin-left: auto;
  margin-right: auto;
}

.dashboard-wrapper {
  padding-left: 50px;
  padding-right: 50px;
  margin-left: auto;
  margin-right: auto;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #222;
}

ul {
  list-style:none;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: "Roboto", sans-serif;
}

.sr-only {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  color: #fff;
  background-color: #2fa0f6;
  min-width: 120px;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  text-align: center;
}

.button svg {
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  fill: #fff;
}

.button span {
  display: none;
}

.dropbtn {
  background-color: transparent;
  color: black;
  padding: 0.5rem 0.5rem;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #f1f1f1}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: transparent;
}
@media (min-width: 600px) {
  .button span {
    display: initial;
  }
}

.button--icon {
  min-width: initial;
  padding: 0.5rem;
}

.lang-select {
  display: flex;
  background-color: rgba(0, 0, 0, 0);
  transition: all .2s ease-out;
  border-radius: 3px;
  padding: 0 .2em 0 .7em;
  line-height: 35px;
  height: 35px;
}