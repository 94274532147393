body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f7f7f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.main-container {
  min-height: 100vh;
  margin-top: 20px;
  position: relative;
  margin-bottom: 20px;
  margin-left: 200px !important;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
h1,h2,h3,h4,h5,h6{
  color: #2b3e51;
  font-weight: 600;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
.main-top-header-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top:0;
  width: 100%;
  bottom: auto;
  z-index: 9999;
  width: 100%;
}
.main-content-wrapper {
  margin-top: 130px !important;
  max-width: 95%;
  margin: 0 auto;
}
.menu-item-area {
  background: #fff;
  box-shadow: 0 2px 4px rgb(15 34 58 / 12%);
  margin: 0;
  padding: 0.75rem 1.5rem;
}
.menu-item-area a {
  color: #6d7080;
  padding: 0.75rem 1.5rem;
  font-size: 0.9375rem;
}
.menu-item-area a:hover{
  text-decoration: none;
  color: #2196f3;
}
.title-info-header{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.mini-info-right{
margin-left: auto;
color: #6d7080;
font-size: 0.9rem;
}
.card-hmdb{
  box-shadow: 0 1px 2px rgb(56 65 74 / 15%) !important;
  margin-bottom: 1.5rem;
  border-radius: 0.25rem;
  word-wrap: break-word;
}
.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}
.card-animate{
  transition: all .4s;
}
.card-animate:hover{
  box-shadow: 0 5px 10px rgb(30 32 37 / 12%);
  transform: translateY(calc(-1.5rem / 5));
}
.card-info-block{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.text-muted{
  color: #878a99!important;
  font-size: 0.8rem;
}
.card-info-block h2{
margin-bottom: 10px;
}
.btn-link{
  color: #2196f3;
  font-size: 0.8rem;
  display: flex;
}
.btn-link svg{
  margin-left: 5px;
}
.card-header{
  font-size: 16px;
  margin: 0;
}
.welcome-into-title{
  margin-left: 20px;
  text-align: right;
}
.welcome-into-title h4{
  margin: 0;
  color: #2b3e51;
  font-weight: 600;
  opacity: 0.8;
  font-size: 15px;
}
.welcome-into-title p{
  margin: 0;
}
.d-flex{
  display: flex;
}
.align-items-center{
  align-items: center;
}
.flex-flow-col{
  flex-flow: column;
}
.time{
  margin-top: 10px !important;
  color: #2b3e51 !important;
  font-weight: 600;
  opacity: 0.8;
}
.w-100{
  width: 100%;
}
.income-chart-area img{
  height: 361px;
}
.top-filter-area{
  margin-bottom: 20px;
}
.me-3{
  margin-right: 30px !important;
}
.ml-auto{
  margin-left: auto;
}
.paper-card{
  margin-bottom: 15px;
  min-height: 110px;
  box-shadow: none !important;
}
.paper-card h6{
  text-transform: capitalize;
  font-weight: 600;
}
.ms-1{
  margin-left: 5px;
}
.mt-2{
  margin-top: 10px;
}
.me-1{
  margin-right: 10px;
}
.mt-3{
  margin-top: 20px !important;
}